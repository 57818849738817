import Layout from "./components/Layout";
import { useRef, useState, useEffect, useContext } from "react";
import classes from "./App.module.scss";
import LoginForm from "./components/LoginForm";
import ResetForm from "./components/ResetForm";
import ErrorPage from "./components/ErrorPage";

import SendEmailForm from "./components/SendEmailForm";

import LoginHead from "./components/LoginHead";
import Landing from "./components/Landing";
import ValidUserContext from "./authCheck";
import { useThirdPartyCookieCheck } from './useThirdPartyCookieCheck';
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';

function App() {
  const validUserContext = useContext(ValidUserContext);
  const cookiesEnabled = true;

  const [isIdle, setIsIdle] = useState(false);

  const handleOnIdle = () => {
    setIsIdle(true);
    validUserContext.logoutUser()
    // You can also log the user out or perform other actions here
  };

  const handleOnActive = () => {
    setIsIdle(false);
  };

  const handleOnAction = () => {
    setIsIdle(false);
  };

  if (validUserContext.isLoggedIn) {
    return (
      <IdleTimerProvider
      timeout={2 * 60 * 60 * 1000} // 2 hours in milliseconds
      onIdle={handleOnIdle}
      onActive={handleOnActive}
      onAction={handleOnAction}
      >
        <Landing></Landing>
      </IdleTimerProvider>
    );
  }

  var form
  if (!cookiesEnabled){
    form = <ErrorPage />
  }
  else if(validUserContext.pwdResetTokenValue) {
    form = <ResetForm />
  } else if (validUserContext.isForgotPwd) {
    form = <SendEmailForm />
  } else {
    form = <LoginForm />
  }

  return (
    <IdleTimerProvider
    timeout={2 * 60 * 60 * 1000} // 2 hours in milliseconds
    onIdle={handleOnIdle}
    onActive={handleOnActive}
    onAction={handleOnAction}
    >
      <Layout>
        {validUserContext.isLoggingIn? 
          ( <div className={classes.spinnerContainer}>
              <div className={classes.spinner}></div>
            </div>
          ):(
            <div></div>
          )
        }
        <LoginHead />
        {form}
      </Layout>
    </IdleTimerProvider>
  );
}

export default App;

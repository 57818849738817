import classes from "./Navigation.module.scss";
import {useRef, useEffect, useState, useContext} from "react";
import ValidUserContext from "../authCheck";
import dividerIcon from "../assets/akar-icons_divider.svg";


const Folder = ({ folderId, folderData, onFolderClick, navigationData }) => {
    const { name, children } = folderData;
    
    var dashboardURL = JSON.parse(localStorage.getItem("dashboard-url"));
    if (navigationData[folderId]['link']) {
        dashboardURL = dashboardURL.replace("#/site", "t")
        dashboardURL = dashboardURL +navigationData[folderId]['link'].replace('/sheets','') + '.png'
    }
    return (
        <div className={classes.folder} onClick={() => onFolderClick(folderId)} style={{ cursor: 'pointer' }}>
            {navigationData[folderId]['link'] ? (
                <div className={classes.graphicon}>
                    {/* <img src={dashboardURL} 
                        width="50" height="50"></img> */}
                </div>
            ) : (
                <div className={classes.foldericon}></div>
            )}
            {name}
        </div>
    );
  };


const Navigation = ({defaultFolder ,onDashboardClick, onFolderClick}) => {
    const navigationData = JSON.parse(localStorage.getItem("navigation"));

    const rootFolders = Object.entries(navigationData).filter(
        ([folderId, folderData]) => folderData.parent_id === 'root'
      );

    const [currentFolder, setCurrentFolder] = useState(rootFolders);
        
    useEffect(() => {
        if (defaultFolder != '' && defaultFolder != 'Home') {
            var defaultfolderId =  Object.entries(navigationData).filter(
                ([folderId, folderData]) => folderData.name === defaultFolder
            );    
            var selectedFolder = Object.entries(navigationData).filter(
                ([folderId, folderData]) => folderData.parent_id === defaultfolderId[0][0]
            );
            setCurrentFolder(selectedFolder)
        } else {
            var defaultfolderId =  Object.entries(navigationData).filter(
                ([folderId, folderData]) => folderData.parent_id === 'root'
            );    
            setCurrentFolder(defaultfolderId)
        }
      }, [defaultFolder]);

  
    const handleFolderClick = (clickedFolderId) => {
        if (navigationData[clickedFolderId]['link']){
            onDashboardClick(navigationData[clickedFolderId]['link'])
        } else {
            onFolderClick(navigationData[clickedFolderId]['name'])
        }
        var newFolder = Object.entries(navigationData).filter(
            ([folderId, folderData]) => folderData.parent_id === clickedFolderId
        );
        setCurrentFolder(newFolder);
    };
  
    return (
      <div>
        <h2>Folder Navigation</h2>
        {currentFolder.map(([folderId, folderData]) => (
          <Folder key={folderId} folderId={folderId} folderData={folderData} onFolderClick={handleFolderClick} navigationData={navigationData} />
        ))}
      </div>
    );
  };

  export default Navigation;

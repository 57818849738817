import classes from "./Layout.module.scss";

const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

const Layout = (props) => {
  return <div className={`${isMobileDevice() ? classes.contentMobile : classes.content}`}>{props.children}</div>;
};

export default Layout;

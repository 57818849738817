import { useRef, useEffect, useContext } from "react";

import classes from "./LoginForm.module.scss";
import usernameIcon from "../assets/fa-user.svg";
import passwordIcon from "../assets/carbon_password.svg";
import ValidUserContext from "../authCheck";

let isInitial = true;
const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};


function LoginForm() {
  const validUserContext = useContext(ValidUserContext);

  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  useEffect(() => {
    if (isInitial) {
      validUserContext.localAuthCheck();
      isInitial = false;
    }
  }, [validUserContext]);

  const submitHandler = (event) => {
    event.preventDefault();

    validUserContext.apiAuthCheck(
      emailInputRef.current.value,
      passwordInputRef.current.value
    );
  };

  const handleForgotClick = () => {
    validUserContext.forgotPassword()
  }

  return (
    <div className={classes.logincontainer}>
      <form onSubmit={submitHandler} className={`${isMobileDevice() ? classes.formMobile : classes.form}`}>
        <div className={classes.loginInstructions}>
          Analytics Dashboard
        </div>
        <div>
          <input
            className={classes.input}
            type="email"
            id="user-name"
            name="user-name"
            autoComplete="on"
            placeholder="Username"
            ref={emailInputRef}
            required={!validUserContext.isLoggedIn}
          ></input>
        </div>
        <div>
          <input
            className={classes.input}
            type="password"
            id="user-password"
            name="user-password"
            autoComplete="off"
            placeholder="Password"
            ref={passwordInputRef}
            required={!validUserContext.isLoggedIn}
          ></input>
        </div>
        <div className={classes.forgot} onClick={() => handleForgotClick()}>FORGOT PASSWORD</div>
        <button
          className={classes.loginBtn}
          disabled={validUserContext.isLoggedIn}
        >
          {validUserContext.isLoggedIn ? "Already logged in" : "LOG IN"}
        </button>
      </form>
      <div className={classes.bottomtext} >Copyright &copy; 2024 Barry Road Partners. All rights reserved.</div>

    </div>
    
  );
}

export default LoginForm;

import classes from "./Landing.module.scss";
import {useRef, useEffect, useState, useContext, useCallback} from "react";
import ValidUserContext from "../authCheck";
import signoutIcon from "../assets/akar-icons_signout.svg";
import pdfIcon from "../assets/fa_pdf.svg";
import csvIcon from "../assets/akar-icons_csv.svg";
import pwrpIcon from "../assets/akar-icons_pwrp.svg";
import moontideLogo from "../assets/moontide_logo.svg";
import menuIcon from "../assets/fa-menu.svg";

import pngIcon from "../assets/akar-icons_png.svg";
import dividerIcon from "../assets/akar-icons_divider.svg";
import Dashboard from "./Dashboard"; 
import transparent_logo from "../assets/sideLogo.png";

import dl_transparent from "../assets/dl_logo_transparent.jpg";
import Navigation from "./Navigation";

const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

const Landing = () => {
    const [activeButton, setActiveButton] = useState(0);
    const [activeDashboard, setActiveDashboard] = useState(true);
    const [activeURL, setActiveURL] = useState('OhioPortal/SiteLegend');
    const [defultFolder, setDefaultFolder] = useState('Home');
    const [defultState, setDefaultState] = useState('texas');

    const [breadCrumbs, setBreadCrumbs] = useState(['Home']);
    const [currentButtons, setCurrentButtons] = useState(['Site Legend', 'Fentanyl']);
    const [currentLinks, setCurrentLinks] = useState(['OhioPortal/SiteLegend', 'OhioPortal/Fentanyl']);
    const [menuOpen, setMenuOpen] = useState(isMobileDevice()? false: true);


    //const [dashboardRef, setDashboardRef] = useState(undefined)
    const dashboardRef = useRef(null)

    const validUserContext = useContext(ValidUserContext);

    const navigationData = JSON.parse(localStorage.getItem("navigation"));

    const currentNav = Object.entries(navigationData).filter( 
      function ([id, data]) {
          if (data.link !=null)
            return data.link
      }
    )

    const sortedNav = currentNav.sort((a, b) => {
      if(a[1].name > b[1].name) {
        // If two elements have same number, then the one who has larger rating.average wins
        return 1;
      } else {
        // If two elements have different number, then the one who has larger number wins
        return -1;
      }
    });

    useEffect(() => {
      console.log("Reading localstorage")
      const state = JSON.parse(localStorage.getItem("group")).toLowerCase();
      if (state) {
        setDefaultState(state);
        if(sortedNav[0] != undefined)
          setActiveURL(sortedNav[0][1].link)
      }
    }, []);

    const handleDashboardClick = (link) => {
        setActiveDashboard(true)
        setActiveURL(link)
    }

    const handleFolderClick = (name) => {
      setDefaultFolder(name)
      breadCrumbs.push(name)
      setBreadCrumbs(breadCrumbs)
    }

    const handleLogoutClick = () => {
        validUserContext.logoutUser()
    }
  
    const handleExportPDFClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[0].displayDialogAsync("export-pdf");
    }

    const handleExportCSVClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].displayDialogAsync("export-csv");
    }

    const handleExportPNGClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].exportImageAsync();
    }

    const handleExportPWRPClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].displayDialogAsync("export-powerpoint");
    }


    const handleLogoClick = () => {
      setActiveDashboard(false)
    }

    const handleButtonClick = (tabIndex) => {
      validUserContext.localAuthCheck();
      setActiveButton(tabIndex);
      setActiveURL(sortedNav[tabIndex][1].link)
      if (isMobileDevice() ){
        setMenuOpen(false)
      }
  };

    const handleBreadCrumbClick = (folderName) => {
      setActiveDashboard(false)
      setDefaultFolder(folderName)
      let indexToRemove = breadCrumbs.indexOf(folderName);
      let newBreadCrumbs
      // Remove elements after the specific element
      if (indexToRemove !== -1) {
        newBreadCrumbs = breadCrumbs.slice(0, indexToRemove + 1);
      }
      setBreadCrumbs(newBreadCrumbs)
    }

    const handleMenuClick  = () => {
      if (menuOpen) {
        setMenuOpen(false)
      } else {
        setMenuOpen(true)
      }
    };

    const renderContent = () => {
      if (activeDashboard)
          return renderDashboard()
      else 
          return renderNavigation()
    } 

    const renderNavigation = () => {
      return (
        <div>
            <Navigation defaultFolder={defultFolder} onDashboardClick={handleDashboardClick} onFolderClick={handleFolderClick}></Navigation>
        </div>
      )
    }


    const renderDashboard = () => {
      return (
        <div>
            <Dashboard dashboardLink={activeURL}></Dashboard>
        </div>
      )
    };

    const renderButtons = () => {  
      return sortedNav.map((buttonText, index) => {
        if (activeButton === index) {
            return (<div className={`${classes.sideButton} ${classes.active}`} onClick={() => handleButtonClick(index)}>{buttonText[1].name.replace(/^\d+\.\s*/, '')}</div>)
        } else {
            return (<div className={`${classes.sideButton}`} onClick={() => handleButtonClick(index)}>{buttonText[1].name.replace(/^\d+\.\s*/, '')}</div>)
        }
      });
    };
    var stateLink = 'https://storage.googleapis.com/stercus-portal-artifacts/'+defultState+'-state-seal.svg'

    return (
      <div className={`${classes.landing}`}>
        <div className={`${menuOpen ? `${isMobileDevice() ? classes.sidebarMobile : classes.sidebar}` : classes.sidebarClosed}`}>  
          <div className={`${classes.sidebarlogo}`}/>
          <img className={`${isMobileDevice() ? classes.sidebarlogoMobile : classes.sidebarlogo}`} src={transparent_logo}></img>
          <img className={`${isMobileDevice() ? classes.sidebarStateMobile : classes.sidebarState}`} src={stateLink}></img>
          <div className={`${classes.sideState}`}>{defultState.charAt(0).toUpperCase()+ defultState.slice(1)}</div>
          <div className={`${classes.sideHeader}`}>Navigation</div>
          {renderButtons()}
        </div>
        <div className={`${isMobileDevice() ? classes.contentblockMobile : classes.contentblock}`}>
          <div className={`${classes.toolbar}`}>
              <img
              className={classes.icon}
              src={signoutIcon}
              alt="Signout icon"
              htmlFor="sign-out"
              onClick={() => handleLogoutClick()}
              ></img>
              <span className={`${classes.logout}`} onClick={() => handleLogoutClick()}>LOGOUT</span>
              <img
              className={classes.pdficon}
              src={pdfIcon}
              alt="PDF icon"
              htmlFor="download-pdf"
              onClick={() => handleExportPDFClick()}
              ></img>
              <span className={`${classes.logout}`} onClick={() => handleExportPDFClick()}>DOWNLOAD</span>
              {isMobileDevice()? 
                (
                  <img
                    className={classes.menuicon}
                    src={menuIcon}
                    alt="Menu icon"
                    htmlFor="menu-click"
                    onClick={() => handleMenuClick()}
                  ></img>
                  ):(
                    <div></div>
                )
              }
          </div>
          <div className={classes.dashboardblock} ref={dashboardRef} >
              {renderContent()}
          </div>
        </div>
        
      </div>
    );
  };
  
  export default Landing;

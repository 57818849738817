import classes from "./LoginHead.module.scss";
import transparent_logo from "../assets/stercus_logo.svg"
import favicon from "../assets/LTL_Favicon.png";
import { useContext} from "react";
import ValidUserContext from "../authCheck";

function LoginHead() {
  const validUserContext = useContext(ValidUserContext);

  const handleReset = () => {
    validUserContext.reset()
  }
  var actionText = 'Please enter your E-mail and your Password'
  if(validUserContext.isForgotPwd) {
    actionText = 'Please enter your E-mail'
  } else if (validUserContext.pwdResetTokenValue){
    actionText = 'Please enter and confirm your Password'
  }

  return (
    <div> 
      <a href="/"><img className={classes.loginLogo} src={transparent_logo} onClick={() => handleReset()}></img></a>
      <title className={classes.loginTitle}></title>
    </div>
  );
}

export default LoginHead;
